import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import { Col, Container } from "react-bootstrap";
//import styles from "../style/guide-template.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const styles = {};

export default ({ data: {mdx} }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{mdx.frontmatter.title}</title>
      </Helmet>
      <div className={styles.guideLayout}>
        <Container className={styles.guideContainer}>
          <Col>
            <Link style={{float: 'right', color: 'inherit'}} to="/"><FontAwesomeIcon icon={faTimes} /></Link>
            <h1 style={{textAlign: 'center'}}>{mdx.frontmatter.title}</h1>
            <MDXRenderer>{mdx.body}</MDXRenderer>
          </Col>
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
      }
    }
  }
`;
